import React, { FC, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../services/api";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import SubscriptionModal from "../../components/SubscriptionModal";
import { useAuth } from "../../context/AuthContext";
import { useAlert } from "../../context/AlertContext";

interface Lesson {
  _id: string;
  title: string;
  description: string;
  duration: number;
  videoUrl: string;
  resources: string[];
  isPreview: boolean;
  tools: {
    id: string;
    name: string;
    url: string;
  };
}

interface ContentSection {
  title: string;
  description: string;
  sectionPosition: number;
  lessons: Lesson[];
}

interface DetailedDescription {
  overview: string;
  certification: string;
  targetAudience: string;
  learningObjectives: string[];
}

interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  author: string;
  price: number;
  rating: number;
  reviews: number;
  categoryId: {
    _id: string;
    name: string;
  };

  detailedDescription: DetailedDescription;
  content: ContentSection[];

  totalLessons: number;
  certificate: boolean;
  language: string;
  quizzes: number;
  isActive: boolean;
  requiresSubscription: boolean;
  subscriptionTier?: "basic" | "premium";

  createdAt: string;
  updatedAt: string;
}

export type { Course, Lesson, ContentSection, DetailedDescription };

interface Props {
  isEnrolled: boolean;
}

const CourseDetails: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const [activeTab, setActiveTab] = useState<"videos" | "details">("videos");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    // Set subscription status from user object
    if (user?.isSubscribed) {
      setHasSubscription(true);
    } else {
      setHasSubscription(false);
    }
  }, [user]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get(`/courses/${id}`);
        console.log("data", data);
        setCourse(data);
        if (data.content && data.content.length > 0) {
          // Set first lesson as selected lesson
          setSelectedLesson(data.content[0].lessons[0]);
        }
      } catch (err) {
        setError("Failed to load course details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  const handleSubscribe = () => {
    // Add subscription logic here
    setShowSubscriptionModal(false);
  };

  const handleEnrollment = async () => {
    if (!hasSubscription) {
      setShowSubscriptionModal(true);
      return;
    }

    try {
      setEnrolling(true);
      const { data } = await api.post(`/courses/${id}/enroll`);

      // Update user in localStorage with response data
      localStorage.setItem("user", JSON.stringify(data.user));

      showAlert("Enrollment successful", "success");
      navigate("/learning");
    } catch (error: any) {
      console.error("Failed to enroll:", error.response.data.message);
      showAlert(`Failed to enroll: ${error.response.data.message}`, "error");
    } finally {
      setEnrolling(false);
    }
  };

  useEffect(() => {
    // Check if user is enrolled in this course
    // console.log("user?.enrolledCourses", user);
    // console.log("id", id);
    if (user?.enrolledCourses && id && user.enrolledCourses.includes(id)) {
      setIsEnrolled(true);
    }
  }, [user, id]);

  const handleNavigateLesson = (direction: "prev" | "next") => {
    if (!course || !selectedLesson) return;

    // Find current section and lesson indices
    let currentSectionIndex = -1;
    let currentLessonIndex = -1;

    course.content.forEach((section, sIndex) => {
      section.lessons.forEach((lesson, lIndex) => {
        if (lesson._id === selectedLesson._id) {
          currentSectionIndex = sIndex;
          currentLessonIndex = lIndex;
        }
      });
    });

    if (currentSectionIndex === -1) return;

    const currentSection = course.content[currentSectionIndex];

    if (direction === "prev") {
      if (currentLessonIndex > 0) {
        // Previous lesson in same section
        setSelectedLesson(currentSection.lessons[currentLessonIndex - 1]);
      } else if (currentSectionIndex > 0) {
        // Last lesson of previous section
        const prevSection = course.content[currentSectionIndex - 1];
        setSelectedLesson(prevSection.lessons[prevSection.lessons.length - 1]);
      }
    } else {
      if (currentLessonIndex < currentSection.lessons.length - 1) {
        // Next lesson in same section
        setSelectedLesson(currentSection.lessons[currentLessonIndex + 1]);
      } else if (currentSectionIndex < course.content.length - 1) {
        // First lesson of next section
        setSelectedLesson(course.content[currentSectionIndex + 1].lessons[0]);
      }
    }
  };

  const handleRepeat = () => {
    setIsRepeat(!isRepeat);
  };

  const handleSkipTime = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime += seconds;
    }
  };

  const handleEnterClassroom = () => {
    // Navigate to CourseVideoDetails if enrolled
    navigate(`/course/${id}/video`);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner size="large" color="#0284C7" />
      </div>
    );
  }

  if (error || !course) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {error || "Course not found"}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F9FAFB] py-8">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Column - Course Content */}
          <div className="col-span-8">
            {/* Course Video */}
            <div className="bg-white rounded-2xl overflow-hidden shadow-sm">
              <div
                className="aspect-w-16 aspect-h-9 bg-gray-900 relative"
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
              >
                {selectedLesson && (
                  <>
                    <div
                      className={`absolute inset-x-0 top-1/2 -translate-y-1/2 flex items-center justify-between px-4 z-10 pointer-events-none transition-opacity duration-300 ${
                        showControls ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={() => handleNavigateLesson("prev")}
                        className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity pointer-events-auto"
                        aria-label="Previous lesson"
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      <div className="flex gap-4 pointer-events-auto">
                        <button
                          onClick={() => handleSkipTime(-10)}
                          className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity"
                          aria-label="Rewind 10 seconds"
                        >
                          <i className="fas fa-undo"></i>
                        </button>
                        <button
                          onClick={() => handleSkipTime(10)}
                          className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity"
                          aria-label="Forward 10 seconds"
                        >
                          <i className="fas fa-redo"></i>
                        </button>
                      </div>
                      <button
                        onClick={() => handleNavigateLesson("next")}
                        className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity pointer-events-auto"
                        aria-label="Next lesson"
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                    <div
                      className={`absolute bottom-4 right-4 z-10 pointer-events-none transition-opacity duration-300 ${
                        showControls ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={handleRepeat}
                        className={`p-2 mb-12 rounded-full transition-opacity pointer-events-auto ${
                          isRepeat
                            ? "bg-blue-500 text-white"
                            : "bg-black bg-opacity-50 text-white hover:bg-opacity-75"
                        }`}
                        aria-label="Toggle repeat"
                      >
                        <i className="fas fa-repeat"></i>
                      </button>
                    </div>
                    <video
                      ref={videoRef}
                      src={selectedLesson.videoUrl}
                      controls={
                        selectedLesson.isPreview ||
                        isEnrolled ||
                        hasSubscription
                      }
                      className="w-full h-full object-contain"
                      poster={course.thumbnail}
                      controlsList="nodownload"
                      onContextMenu={(e) => e.preventDefault()}
                      disablePictureInPicture
                      loop={isRepeat}
                    />
                    {!selectedLesson.isPreview &&
                      !isEnrolled &&
                      !hasSubscription && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                          <div className="text-center space-y-4">
                            <i className="fas fa-lock text-4xl text-white mb-2"></i>
                            <p className="text-white font-medium">
                              Subscribe to unlock this lesson
                            </p>
                            <button
                              onClick={() => setShowSubscriptionModal(true)}
                              className="px-6 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-lg font-medium transition-colors"
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>

              {/* Course Info Tabs */}
              <div className="border-b border-gray-200">
                <div className="flex gap-8 px-8 -mb-px">
                  <button
                    onClick={() => setActiveTab("videos")}
                    className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors ${
                      activeTab === "videos"
                        ? "border-blue-600 text-blue-600"
                        : "border-transparent text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    Course Videos
                  </button>
                  <button
                    onClick={() => setActiveTab("details")}
                    className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors ${
                      activeTab === "details"
                        ? "border-blue-600 text-blue-600"
                        : "border-transparent text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    Details
                  </button>
                </div>
              </div>

              <div className="p-8">
                <h1 className="text-2xl font-bold mb-4">{course.title}</h1>
                <div className="flex items-center gap-6 text-sm text-gray-600 mb-6">
                  <div className="flex items-center gap-2">
                    <i className="fas fa-star text-yellow-400"></i>
                    <span>{course.rating.toFixed(1)}</span>
                    <span>({course.reviews} reviews)</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <i className="fas fa-user"></i>
                    <span>{course.author}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <i className="fas fa-folder"></i>
                    <span>{course.categoryId.name}</span>
                  </div>
                </div>
                <p className="text-gray-600 leading-relaxed">
                  {course.description}
                </p>
              </div>

              {/* Course Content Sections */}
              {activeTab === "details" && (
                <div className="p-8">
                  <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Course Details</h2>
                    <p className="text-gray-600 mb-6">
                      {course.detailedDescription?.overview ||
                        "No overview available"}
                    </p>
                  </section>

                  <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Certification</h2>
                    <p className="text-gray-600 mb-6">
                      {course.detailedDescription?.certification ||
                        "No certification details available"}
                    </p>
                  </section>

                  <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Target Audience</h2>
                    <p className="text-gray-600 mb-6">
                      {course.detailedDescription?.targetAudience ||
                        "No target audience specified"}
                    </p>
                  </section>

                  <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">
                      Learning Objectives
                    </h2>
                    <ul className="space-y-4">
                      {course.detailedDescription?.learningObjectives?.map(
                        (objective: string, index: number) => (
                          <li key={index} className="flex items-start gap-3">
                            <span className="text-orange-500 mt-1">•</span>
                            <span className="text-gray-600">
                              {JSON.parse(objective)}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </section>
                </div>
              )}
            </div>
          </div>

          {/* Right Column */}
          <div className="col-span-4">
            {/* Price Card */}
            <div className="bg-white rounded-2xl shadow-sm sticky p-6 mb-6">
              <div className="flex justify-between items-center mb-6">
                <div className="text-3xl font-bold text-gray-900">
                  {hasSubscription ? "Subscribed" : `$${course.price}`}
                </div>
                {isEnrolled ? (
                  <button
                    onClick={handleEnterClassroom}
                    className="bg-green-600 hover:bg-green-700 text-white px-6 py-2.5 rounded-lg text-sm font-medium transition-colors"
                  >
                    Start Learning
                  </button>
                ) : (
                  <button
                    onClick={handleEnrollment}
                    disabled={enrolling}
                    className={`${
                      hasSubscription
                        ? "bg-[#0284C7] hover:bg-blue-600"
                        : "bg-orange-500 hover:bg-orange-600"
                    } text-white px-6 py-2.5 rounded-lg text-sm font-medium transition-colors`}
                  >
                    {enrolling ? (
                      <LoadingSpinner size="small" color="white" />
                    ) : hasSubscription ? (
                      "Enroll Now"
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                )}
              </div>
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <i className="fas fa-certificate text-blue-500"></i>
                  <span className="text-gray-600">
                    Certificate of completion
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <i className="fas fa-globe text-blue-500"></i>
                  <span className="text-gray-600">{course.language}</span>
                </div>
                <div className="flex items-center gap-3">
                  <i className="fas fa-infinity text-blue-500"></i>
                  <span className="text-gray-600">Full lifetime access</span>
                </div>
                <div className="flex items-center gap-3">
                  <i className="fas fa-question-circle text-blue-500"></i>
                  <span className="text-gray-600">
                    {course.quizzes} quizzes
                  </span>
                </div>
              </div>
            </div>

            {/* Course Videos List */}
            <div className="bg-white rounded-2xl shadow-sm sticky top-24">
              <div className="p-6 border-b">
                <h2 className="text-xl font-bold">Course Videos</h2>
                <p className="text-gray-500 text-sm mt-1">
                  {course.totalLessons} lessons
                </p>
              </div>
              <div className="divide-y max-h-[600px] overflow-y-auto">
                {course.content && course.content.length > 0 ? (
                  course.content.map((section, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        section.lessons[index] &&
                        setSelectedLesson(section.lessons[index])
                      }
                      className={`w-full p-4 text-left hover:bg-gray-50 transition-colors ${
                        selectedLesson?._id === section.lessons[index]?._id
                          ? "bg-gray-50"
                          : ""
                      }`}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex-shrink-0">
                          <div className="w-10 h-10 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-medium">
                            {index + 1}
                          </div>
                        </div>
                        <div className="flex-grow">
                          <h3 className="font-medium mb-1">{section.title}</h3>
                          {section.lessons[index] && (
                            <div className="flex items-center gap-2 text-sm text-gray-500">
                              <i className="fas fa-clock"></i>
                              <span>{section.lessons[index].duration}</span>
                            </div>
                          )}
                        </div>
                        {section.lessons[index] &&
                          !section.lessons[index].isPreview &&
                          !isEnrolled &&
                          !hasSubscription && (
                            <i className="fas fa-lock text-gray-400"></i>
                          )}
                      </div>
                    </button>
                  ))
                ) : (
                  <div className="p-4 text-center text-gray-500">
                    No lessons available
                  </div>
                )}
              </div>
            </div>

            {/* Right Column - Course Info Card */}
            {/*
            <div className="bg-white rounded-2xl shadow-sm p-6 mb-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Price</span>
                  <span className="text-orange-500 font-bold">$39.99</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Instructor</span>
                  <span className="font-bold underline">Daniel Walter</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Ratings</span>
                  <div className="flex text-yellow-400">{"★".repeat(5)}</div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Students</span>
                  <span className="font-bold">803</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Lessons</span>
                  <span className="font-bold">30</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Quizzes</span>
                  <span className="font-bold">5</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Certificate</span>
                  <span className="font-bold">Yes</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Language</span>
                  <span className="font-bold">English</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Access</span>
                  <span className="font-bold">Lifetime</span>
                </div>
              </div>

              <div className="mt-6 space-y-3">
                <button
                  onClick={() => setShowSubscriptionModal(true)}
                  className="w-full bg-[#0284C7] text-white py-3 rounded-full text-sm font-medium hover:bg-blue-600 transition-colors"
                >
                  Subscribe
                </button>
                <button className="w-full text-[#0284C7] py-3 rounded-full text-sm font-medium hover:bg-blue-50 transition-colors">
                  Add to wishlist
                </button>
              </div>
              
            </div>
            */}
          </div>
        </div>
      </div>
      <SubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        onSubscribe={handleSubscribe}
      />
    </div>
  );
};

export default CourseDetails;
