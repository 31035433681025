import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import api from "../services/api";
import { setShowLoginModal } from "../store/authSlice";
import { store } from "../store";
import { refreshAccessToken } from "../utils/auth";

interface User {
  email: string;
  role: string;
  isSuperAdmin: boolean;
  isSubscribed: boolean;
  enrolledCourses: string[];
  name: string;
  avatar?: string;
  isActive: boolean;
  courseProgress: {
    courseId: string;
    progress: number;
  }[];
  subscriptionPlan?: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  getAccessToken: () => Promise<string>;
  setUser: (user: User | null) => void;
  signup: (email: string, password: string, username: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const logout = useCallback(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setUser(null);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const userData = localStorage.getItem("user");
    if (accessToken && userData) {
      setIsAuthenticated(true);
      setUser(JSON.parse(userData));
    }
  }, []);

  const getAccessToken = useCallback(async () => {
    try {
      const currentToken = localStorage.getItem("access_token");
      if (!currentToken) throw new Error("No access token");

      // Decode token to check expiration
      const tokenData = JSON.parse(atob(currentToken.split(".")[1]));
      const expirationTime = tokenData.exp * 1000; // Convert to milliseconds

      // If token will expire in next 2 minutes, refresh it
      if (expirationTime - Date.now() < 120000) {
        try {
          const newToken = await refreshAccessToken();
          //console.log("newToken", newToken);
          localStorage.setItem("access_token", newToken);
          return newToken;
        } catch (refreshError) {
          // If refresh fails, try using current token if it's still valid
          if (Date.now() < expirationTime) {
            return currentToken;
          }

          //console.log("refreshError", refreshError);
          throw refreshError;
        }
      }

      return currentToken;
    } catch (error) {
      logout();
      console.log("error", error);
      throw error;
    }
  }, [logout]);

  // Set up periodic token check
  useEffect(() => {
    if (!isAuthenticated) return;

    const checkToken = async () => {
      try {
        await getAccessToken();
      } catch (error) {
        console.error("Token refresh failed:", error);
      }
    };

    const interval = setInterval(checkToken, 60000);
    return () => clearInterval(interval);
  }, [isAuthenticated, getAccessToken]);

  const login = async (email: string, password: string) => {
    try {
      // Direct login request without token refresh
      const { data } = await api.post(
        "/auth/user/login",
        {
          email,
          password,
        },
        {
          // Skip token refresh interceptor for login
          headers: {
            skipAuthRefresh: "true",
          },
        }
      );

      if (data.access_token) {
        // Store tokens and user data
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("user", JSON.stringify(data.user));

        // Update state
        setUser(data.user);
        setIsAuthenticated(true);

        // Close login modal using store.dispatch
        store.dispatch(setShowLoginModal(false));
      }
    } catch (error) {
      console.error("Login error:", error);
      throw new Error("Login failed. Please check your credentials.");
    }
  };

  const signup = async (email: string, password: string, username: string) => {
    try {
      const { data } = await api.post("/auth/user/register", {
        email,
        password,
        username,
      });

      if (data.access_token) {
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Signup error:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        getAccessToken,
        setUser,
        signup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within AuthProvider");
  return context;
};
