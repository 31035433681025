import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

interface LandingLayoutProps {
  children: React.ReactNode;
}

const LandingLayout: FC<LandingLayoutProps> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="fixed w-full z-50 transition-all duration-300 backdrop-blur-sm bg-black/70 border-b border-gray-700">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              <img src="/logo_text.png" alt="Code Coast" className="h-8" />
            </Link>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-white p-2"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center">
              <a
                href="#home"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname !== "/") {
                    window.location.href = "/";
                  } else {
                    document
                      .getElementById("home")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="text-white uppercase text-sm px-8 py-2 cursor-pointer border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
              >
                HOME
              </a>

              <Link
                to="/code-school"
                className="text-[#14b7cd] uppercase text-sm px-8 py-2 border border-[#14b7cd] mx-2"
              >
                JOIN OUR CODE SCHOOL
              </Link>
              <Link
                to="/startup-school"
                className="text-[#14b7cd] uppercase text-sm px-8 py-2 border border-[#14b7cd] mx-2"
              >
                JOIN OUR STARTUP SCHOOL
              </Link>
              <a
                href="#services"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("services")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-white uppercase text-sm px-8 py-2 cursor-pointer border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
              >
                SERVICES
              </a>
              <a
                href="#projects"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("projects")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-white uppercase text-sm px-8 py-2 cursor-pointer border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
              >
                PROJECTS
              </a>
              <a
                href="#contact"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("contact")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
                className="text-white uppercase text-sm px-8 py-2 cursor-pointer border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
              >
                CONTACT
              </a>
            </nav>
            <nav
              className={`${
                isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
              } md:hidden transition-all duration-300 overflow-hidden`}
            >
              <div className="py-4 space-y-2">
                <a
                  href="#home"
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.location.pathname !== "/") {
                      window.location.href = "/";
                    } else {
                      document
                        .getElementById("home")
                        ?.scrollIntoView({ behavior: "smooth" });
                    }
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  HOME
                </a>

                <Link
                  to="/code-school"
                  onClick={() => setIsMenuOpen(false)}
                  className="block text-[#14b7cd] uppercase text-sm py-2 px-4 border border-[#14b7cd]"
                >
                  JOIN OUR CODE SCHOOL
                </Link>

                <Link
                  to="/startup-school"
                  onClick={() => setIsMenuOpen(false)}
                  className="block text-[#14b7cd] uppercase text-sm py-2 px-4 border border-[#14b7cd]"
                >
                  JOIN OUR STARTUP SCHOOL
                </Link>

                <a
                  href="#services"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("services")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  SERVICES
                </a>
                <a
                  href="#history"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("history")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  HISTORY
                </a>
                <a
                  href="#work-process"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("work-process")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  WORK PROCESS
                </a>
                <a
                  href="#projects"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("projects")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  PROJECTS
                </a>
                <a
                  href="#contact"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("contact")
                      ?.scrollIntoView({ behavior: "smooth" });
                    setIsMenuOpen(false);
                  }}
                  className="block text-white uppercase text-sm py-2 px-4 border border-transparent hover:border-[#14b7cd] hover:text-[#14b7cd] transition-all duration-300"
                >
                  CONTACT
                </a>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main>{children}</main>

      {/* Footer */}
      <footer className="bg-primary text-white py-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About Us</h3>
              <p className="text-gray-300">
                Code Coast is a leading platform for learning programming and
                technology skills.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/courses"
                    className="text-gray-300 hover:text-white"
                  >
                    Courses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/live-classes"
                    className="text-gray-300 hover:text-white"
                  >
                    Live Classes
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pricing"
                    className="text-gray-300 hover:text-white"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Support</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/help" className="text-gray-300 hover:text-white">
                    Help Center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="text-gray-300 hover:text-white"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="text-gray-300 hover:text-white">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect</h3>
              <div className="flex space-x-4">
                {/* Add social media icons/links here */}
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-300">
            <p>
              &copy; {new Date().getFullYear()} Code Coast. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingLayout;
