import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

const services = [
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    title: "WEB AND APP DEVELOPMENT",
    description:
      "Build scalable web applications and mobile solutions using cutting-edge technologies and best practices.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
        />
      </svg>
    ),
    title: "CLOUD TRANSFORMATION",
    description:
      "Modernize your infrastructure with cloud-native solutions and AWS expertise for enhanced scalability and efficiency.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
        />
      </svg>
    ),
    title: "ANALYTICS AND AI",
    description:
      "Leverage data analytics and artificial intelligence to drive insights and automation for your business.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
        />
      </svg>
    ),
    title: "SECURITY",
    description:
      "Implement robust security measures and best practices to protect your digital assets and infrastructure.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2m-6 4h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    title: "TECH TRAINING",
    description:
      "Comprehensive training programs in software development, cloud computing, and digital skills for the modern workforce.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    title: "MANAGED SERVICES",
    description:
      "End-to-end IT service management and support to keep your systems running smoothly and efficiently.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    ),
    title: "STARTUP ACCELERATOR",
    description:
      "Comprehensive support for startups including mentorship, funding access, business development, and technical expertise to accelerate growth.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
    title: "SME BUSINESS SOLUTIONS",
    description:
      "Tailored digital transformation and business growth solutions for small and medium enterprises, including technology integration and process optimization.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
        />
      </svg>
    ),
    title: "INNOVATION HUB",
    description:
      "Access to state-of-the-art facilities, collaborative workspace, and innovation resources to help transform ideas into successful tech ventures.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    title: "FUNDING ACCESS",
    description:
      "Connect with investors, access grant opportunities, and receive guidance on financial planning and investment readiness for your business growth.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    title: "BUSINESS CONSULTING",
    description:
      "Expert guidance on business strategy, market analysis, digital transformation, and sustainable growth planning for startups and SMEs.",
  },
];

const historyEvents = [
  {
    date: "AUG 01, 2019",
    image: "/images/start.jpg",
    title: "CAPE COAST",
    description: "Code Coast was founded in Cape Coast, Ghana.",
  },
  {
    date: "JUN 01, 2022",
    image: "/images/milestone.png",
    title: "SCALED TO 2 CITIES",
    description: "Code Coast scaled to 2 cities in Ghana.",
  },
  // Add more history events as needed
];

const workProcess = [
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        />
      </svg>
    ),
    step: 1,
    title: "DISCUSS",
    description:
      "we curate tailored programs to meet the unique needs of our beneficiaries.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
        />
      </svg>
    ),
    step: 2,
    title: "CREATIVE CONCEPT",
    description:
      "we develop innovative solutions that address the specific challenges of our beneficiaries.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
    step: 3,
    title: "PRODUCTION",
    description:
      "we implement the solutions and monitor the progress to ensure they are effective and sustainable.",
  },
  {
    icon: (
      <svg
        className="w-16 h-16 text-[#14b7cd]"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    ),
    step: 4,
    title: "HAPPY CLIENT",
    description:
      "we celebrate the success of our beneficiaries and the impact of our work.",
  },
];

const projects = [
  {
    image: "/images/digital_skills.png",
    title: "DIGITAL SKILLS PROGRAM",
    category: "DIGITAL SKILLS",
    description:
      "We provide a comprehensive digital skills program to help beneficiaries develop the skills they need to succeed in the digital economy.",
    link: "#",
  },
  {
    image: "/images/land_litigation_security.png",
    title: "LAND LITIGATION PROGRAM",
    category: "SYSTEMS AND SECURITY",
    description:
      "We provide a comprehensive land litigation program to help beneficiaries resolve land disputes and secure their property rights.",
    link: "https://www.landidentify.com",
  },
  {
    image: "/images/cyber_security.png",
    title: "DIGITAL TRANSFORMATION",
    category: "DIGITAL TRANSFORMATION",
    description:
      "We provide a comprehensive digital transformation program to help beneficiaries transform their businesses and operations.",
    link: "#",
  },
];

const skills = [
  {
    title: "DIGITAL MARKETING TRAINING",
    percentage: 100,
    description:
      "We provide a comprehensive digital marketing training program to help beneficiaries develop the skills they need to succeed in the digital economy.",
  },
  {
    title: "PROMOTION OF COMPANIES",
    percentage: 85,
    description:
      "We provide a comprehensive promotion of companies program to help beneficiaries develop the skills they need to succeed in the digital economy.",
  },
  {
    title: "SOFTWARE DEVELOPMENT TRAINING",
    percentage: 100,
    description:
      "We provide a comprehensive software development training program to help beneficiaries develop the skills they need to succeed in the digital economy.",
  },
  {
    title: "BUSINESS DEVELOPMENT SUPPORT",
    percentage: 100,
    description:
      "We provide a comprehensive business development support program to help beneficiaries develop the skills they need to succeed in the digital economy.",
  },
];

const Landing: FC = () => {
  const [currentVideo, setCurrentVideo] = useState("experience");

  const videos = {
    about:
      "https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/what_is_code_coast.mp4",
    philosophy:
      "https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/5118718_Man_Woman_1280x720.mp4",
    principles:
      "https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/1472563_Education_People_1280x720.mp4",
    experience:
      "https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/4973032_African+American_Black_1280x720.mp4",
    smes: "https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/0_Woman_Business_1280x720.mp4",
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        id="home"
        className="h-[90vh] relative bg-black text-white overflow-hidden"
      >
        {/* Background Video */}
        <div className="absolute inset-0">
          <video
            src={
              currentVideo === "about"
                ? videos.about
                : currentVideo === "philosophy"
                ? videos.philosophy
                : currentVideo === "principles"
                ? videos.principles
                : currentVideo === "experience"
                ? videos.experience
                : currentVideo === "smes"
                ? videos.smes
                : videos.about
            }
            autoPlay
            muted
            loop
            className="object-cover w-full h-full opacity-50"
          />
        </div>

        {/* Content */}
        <div className="container mx-auto px-6 relative h-full flex flex-col justify-between py-[35vh]">
          {/* Top content */}
          <div className="max-w-3xl">
            <h1 className="text-6xl md:text-7xl font-bold mb-6">
              CONNECT,
              <br />
              <span className="bg-[#14b7cd] text-white px-2 py-1 rounded-[10px]">
                BUILD,
              </span>
              <br />
              GROW
            </h1>
            <p className="text-xl mb-12 text-gray-300 max-w-2xl">
              Master progressive skills with interactive courses and live
              sessions at our startup school.
            </p>
          </div>

          {/* Bottom icons - now centered */}
          <div className="flex justify-center">
            <div className="flex space-x-16">
              <div
                className="text-center cursor-pointer"
                onClick={() => setCurrentVideo("about")}
              >
                <div className="w-12 h-12 mb-4 mx-auto">
                  <svg
                    className={`w-full h-full ${
                      currentVideo === "about" ? "text-[#14b7cd]" : "text-white"
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <p className="uppercase font-bold tracking-wider text-sm">
                  About Us
                </p>
                <div
                  className={`h-0.5 mt-2 bg-[#14b7cd] transition-all duration-300 ${
                    currentVideo === "about" ? "w-full" : "w-0"
                  }`}
                />
              </div>

              <div
                className="text-center cursor-pointer"
                onClick={() => setCurrentVideo("philosophy")}
              >
                <div className="w-12 h-12 mb-4 mx-auto">
                  <svg
                    className={`w-full h-full ${
                      currentVideo === "philosophy"
                        ? "text-[#14b7cd]"
                        : "text-white"
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="uppercase font-bold tracking-wider text-sm">
                  Agri-Business
                </p>
                <div
                  className={`h-0.5 mt-2 bg-[#14b7cd] transition-all duration-300 ${
                    currentVideo === "philosophy" ? "w-full" : "w-0"
                  }`}
                />
              </div>

              <div
                className="text-center cursor-pointer"
                onClick={() => setCurrentVideo("smes")}
              >
                <div className="w-12 h-12 mb-4 mx-auto">
                  <svg
                    className={`w-full h-full ${
                      currentVideo === "smes" ? "text-[#14b7cd]" : "text-white"
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <p className="uppercase font-bold tracking-wider text-sm">
                  SMEs
                </p>
                <div
                  className={`h-0.5 mt-2 bg-[#14b7cd] transition-all duration-300 ${
                    currentVideo === "philosophy" ? "w-full" : "w-0"
                  }`}
                />
              </div>

              <div
                className="text-center cursor-pointer"
                onClick={() => setCurrentVideo("principles")}
              >
                <div className="w-12 h-12 mb-4 mx-auto">
                  <svg
                    className={`w-full h-full ${
                      currentVideo === "principles"
                        ? "text-[#14b7cd]"
                        : "text-white"
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="uppercase font-bold tracking-wider text-sm">
                  Code School
                </p>
                <div
                  className={`h-0.5 mt-2 bg-[#14b7cd] transition-all duration-300 ${
                    currentVideo === "principles" ? "w-full" : "w-0"
                  }`}
                />
              </div>

              <div
                className="text-center cursor-pointer"
                onClick={() => setCurrentVideo("experience")}
              >
                <div className="w-12 h-12 mb-4 mx-auto">
                  <svg
                    className={`w-full h-full ${
                      currentVideo === "experience"
                        ? "text-[#14b7cd]"
                        : "text-white"
                    }`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                    />
                  </svg>
                </div>
                <p className="uppercase font-bold tracking-wider text-sm">
                  Startup School
                </p>
                <div
                  className={`h-0.5 mt-2 bg-[#14b7cd] transition-all duration-300 ${
                    currentVideo === "experience" ? "w-full" : "w-0"
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 relative">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4">OUR SERVICES</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Empowering startups and SMEs with comprehensive technology
              solutions, business support, and innovation resources to drive
              sustainable growth in the digital economy.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="group p-8 bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="flex justify-center mb-6">
                  <div className="p-3 rounded-lg bg-gray-50 group-hover:text-primary transition-colors duration-300">
                    {service.icon}
                  </div>
                </div>
                <h3 className="text-xl font-bold mb-4 group-hover:text-primary transition-colors duration-300">
                  {service.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {service.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Analysis Section */}
      <section id="about" className="relative">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Left Content */}
          <div className="bg-[#0a1628] text-white p-16 md:p-24">
            <h2 className="text-4xl md:text-5xl font-bold mb-8">ABOUT US</h2>
            <p className="text-lg leading-relaxed mb-8">
              Code Coast: Central Technology Hub is a technology company that
              provides technology solutions to startups and SMEs, with a focus
              on business development, technology consulting, and innovation.
              Startups and SMEs can leverage our expertise to drive growth and
              innovation.
              <br />
              <br />
              We are a team of experienced professionals who are dedicated to
              providing the best possible solutions to our clients.
              <br />
              <br />
              We are a team of experienced professionals who are dedicated to
              providing the best possible solutions to our clients.
            </p>
            <Link
              to="/code-school"
              className="uppercase text-[#14b7cd] border border-[#14b7cd] px-8 py-3 hover:bg-white hover:text-[#14b7cd] transition-colors duration-300"
            >
              READ MORE
            </Link>
          </div>

          {/* Right Image */}
          <div className="relative h-full min-h-[400px] md:min-h-[600px]">
            <video
              src="https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/5742239_Coll_wavebreak_People_1280x720.mp4"
              autoPlay
              muted
              loop
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </section>

      {/* History Section */}
      <section id="history" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4">HISTORY</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Code Coast was founded in 2019 by a team of experienced
              professionals who are dedicated to providing the best possible
              solutions to our clients.
            </p>
          </div>

          <div className="relative">
            {/* Timeline line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-px bg-gray-200" />

            {/* Timeline events */}
            <div className="space-y-24">
              {historyEvents.map((event, index) => (
                <div
                  key={index}
                  className={`flex items-center ${
                    index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                  }`}
                >
                  {/* Content */}
                  <div className="w-1/2 px-12">
                    <div
                      className={`text-sm font-semibold text-[#14b7cd] mb-2 ${
                        index % 2 === 0 ? "text-right" : "text-left"
                      }`}
                    >
                      {event.date}
                    </div>
                  </div>

                  {/* Circle and Image */}
                  <div className="relative">
                    {/* Circle */}
                    <div className="w-32 h-32 rounded-full border-4 border-[#14b7cd] overflow-hidden">
                      <img
                        src={event.image}
                        alt={event.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>

                  {/* Content */}
                  <div className="w-1/2 px-12">
                    <div
                      className={`${
                        index % 2 === 0 ? "text-left" : "text-right"
                      }`}
                    >
                      <h3 className="text-xl font-bold uppercase mb-2">
                        {event.title}
                      </h3>
                      <p className="text-gray-600">{event.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Work Process Section */}
      <section id="work-process" className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4">WORK PROCESS</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We are a team of experienced professionals who are dedicated to
              providing the best possible solutions to our beneficiaries.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {workProcess.map((process, index) => (
              <div key={index} className="text-center group">
                <div className="relative mb-8">
                  {/* Icon */}
                  <div className="flex justify-center mb-6">
                    <div className="p-4 rounded-full bg-white group-hover:bg-[#FF4F4F]/10 transition-colors duration-300">
                      {process.icon}
                    </div>
                  </div>

                  {/* Step Number */}
                  <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                    <div className="w-8 h-8 rounded-full bg-[#14b7cd] text-white flex items-center justify-center font-bold">
                      {process.step}
                    </div>
                  </div>
                </div>

                <h3 className="text-lg font-bold uppercase mb-4 group-hover:text-[#14b7cd] transition-colors duration-300">
                  {process.title}
                </h3>
                <p className="text-gray-600 text-sm">{process.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section id="projects" className="py-20 relative">
        {/* Background Image */}
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage:
              'url("https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/1522.jpg")',
          }}
        />
        {/* Overlay Color */}
        <div className="absolute inset-0 bg-[#14b7cd]/90" />

        {/* Content */}
        <div className="container mx-auto px-6 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4 text-white">
              OUR PROJECTS
            </h2>
            <p className="text-white/80 max-w-2xl mx-auto">
              We are a team of experienced professionals who are dedicated to
              providing the best possible solutions to our beneficiaries.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project, index) => (
              <div
                key={index}
                className="group bg-white overflow-hidden rounded-lg transition-transform duration-300 hover:-translate-y-2"
              >
                {/* Project Image */}
                <div className="relative h-64 overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  />
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <button className="px-6 py-2 border-2 border-white text-white uppercase hover:bg-white hover:text-[#14b7cd] transition-colors duration-300">
                      View Project
                    </button>
                  </div>
                </div>

                {/* Project Info */}
                <div className="p-6">
                  <h3 className="text-xl font-bold uppercase mb-2 group-hover:text-[#14b7cd] transition-colors duration-300">
                    {project.title}
                  </h3>
                  <p className="text-sm text-gray-500 uppercase mb-4">
                    {project.category}
                  </p>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section id="skills" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4">
              HOW TO FIND US
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We are a team of experienced professionals who are dedicated to
              providing the best possible solutions to our beneficiaries.
            </p>
          </div>

          {/* Google Maps Integration */}
          <div className="w-full h-[600px] bg-gray-100">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2399.394080023154!2d-0.8905253168069398!3d5.304715216741551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfde3de3e49f3261%3A0x107519178da4b3b3!2sEkumfi%20Essuehyia%2C%20Esuahyia!5e1!3m2!1sen!2sgh!4v1738850758751!5m2!1sen!2sgh"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Code Coast Location"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>

      {/* Add this section after the "How to Find Us" section and before the Skills section */}
      <section id="contact" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Contact Information */}
            <div>
              <div className="mb-12">
                <h3 className="text-xl font-bold uppercase mb-4">ADDRESS</h3>
                <p className="text-gray-600">Ekumfi Essuehyia, Ekumfiman</p>
              </div>

              <div className="mb-12">
                <h3 className="text-xl font-bold uppercase mb-4">CALL US</h3>
                <p className="text-gray-600">+233 24 086 5997</p>
              </div>

              <div>
                <h3 className="text-xl font-bold uppercase mb-4">EMAIL</h3>
                <p className="text-gray-600">director@codecoast.org</p>
              </div>
            </div>

            {/* Contact Form */}
            <div>
              <form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <input
                    type="text"
                    placeholder="Name..."
                    className="w-full px-4 py-3 bg-gray-100 border border-transparent focus:border-[#14b7cd] focus:bg-white transition-colors duration-300"
                  />
                  <input
                    type="email"
                    placeholder="Email..."
                    className="w-full px-4 py-3 bg-gray-100 border border-transparent focus:border-[#14b7cd] focus:bg-white transition-colors duration-300"
                  />
                </div>

                <input
                  type="text"
                  placeholder="Subject..."
                  className="w-full px-4 py-3 bg-gray-100 border border-transparent focus:border-[#14b7cd] focus:bg-white transition-colors duration-300"
                />

                <textarea
                  placeholder="Message..."
                  rows={6}
                  className="w-full px-4 py-3 bg-gray-100 border border-transparent focus:border-[#14b7cd] focus:bg-white transition-colors duration-300 resize-none"
                />

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-8 py-3 bg-[#14b7cd] text-white uppercase hover:bg-[#14b7cd] transition-colors duration-300 flex items-center group"
                  >
                    Send Message
                    <svg
                      className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section id="achievements" className="py-20 relative">
        {/* Background Image */}
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage:
              'url("https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/1522.jpg")',
          }}
        />
        {/* Overlay Color */}
        <div className="absolute inset-0 bg-[#14b7cd]/90" />

        {/* Content */}
        <div className="container mx-auto px-6 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold uppercase mb-4 text-white">
              OUR ACHIEVEMENTS
            </h2>
            <p className="text-white/80 max-w-2xl mx-auto">
              Our achievements are a testament to the hard work and dedication
              of our team.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {skills.map((skill, index) => (
              <div key={index} className="text-center">
                {/* Percentage Circle */}
                <div className="relative w-48 h-48 mx-auto mb-6">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-40 h-40 bg-white rounded-lg flex items-center justify-center">
                      <span className="text-5xl font-bold text-[#14b7cd]">
                        {skill.percentage}%
                      </span>
                    </div>
                  </div>
                </div>

                {/* Skill Info */}
                <h3 className="text-white text-xl font-bold uppercase mb-4">
                  {skill.title}
                </h3>
                <p className="text-white/80">{skill.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
