import { useQuery } from "@tanstack/react-query";
import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const getPrograms = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/startup-school/programs`
  );
  return data;
};

const Apply: FC = () => {
  const location = useLocation();
  const previousPath = location.state?.from || "/";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    program: previousPath.includes("startup") ? "startup" : "code",
    programId: "",
    education: "",
    experience: "",
    motivation: "",
    expectations: "",
    heardFrom: "",
  });

  // fetch opened programs
  const { data: programs } = useQuery({
    queryKey: ["programs"],
    queryFn: getPrograms,
  });

  const [showThankYou, setShowThankYou] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log("Submitting application");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/startup-school/apply`,
        formData
      );
      setShowThankYou(true);
      setTimeout(() => {
        window.location.href = "/startup-school";
      }, 5000);
    } catch (error) {
      alert("Failed to submit application. Please try again.");
      console.error("Submission error:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
      ...(e.target.name === "program" && { programId: value }),
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-20 bg-[url('https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/1838.jpg')] bg-cover bg-center bg-opacity-5">
      {showThankYou && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl text-center">
            <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
            <p className="mb-4">
              Your application has been submitted successfully.
            </p>
            <p className="text-sm text-gray-500">
              Redirecting in a few seconds...
            </p>
          </div>
        </div>
      )}
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-2">
            {formData.program === "startup"
              ? "Startup School Application"
              : "Code School Application"}
          </h1>
          <p className="text-gray-600 text-center mb-12">
            Please fill out the form below to apply for our{" "}
            {formData.program === "startup" ? "startup" : "code"} school
            program.
          </p>

          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-lg shadow-lg p-8"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                />
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Program
              </label>
              <select
                name="program"
                value={formData.programId}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
              >
                <option value="">Select a program</option>
                {programs?.map((program: any) => (
                  <option key={program._id} value={program._id}>
                    {program.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Educational Background
              </label>
              <textarea
                name="education"
                value={formData.education}
                onChange={handleChange}
                required
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                placeholder="Please describe your educational background..."
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {formData.program === "startup"
                  ? "Business/Entrepreneurship Experience"
                  : "Programming Experience"}
              </label>
              <textarea
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
                placeholder={`Please describe your ${
                  formData.program === "startup"
                    ? "business/entrepreneurship"
                    : "programming"
                } experience...`}
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                What motivates you to join this program?
              </label>
              <textarea
                name="motivation"
                value={formData.motivation}
                onChange={handleChange}
                required
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                What are your expectations from this program?
              </label>
              <textarea
                name="expectations"
                value={formData.expectations}
                onChange={handleChange}
                required
                rows={3}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
              />
            </div>

            <div className="mb-8">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                How did you hear about us?
              </label>
              <select
                name="heardFrom"
                value={formData.heardFrom}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-[#14b7cd] focus:border-[#14b7cd]"
              >
                <option value="">Select an option</option>
                <option value="social">Social Media</option>
                <option value="friend">Friend/Family</option>
                <option value="search">Search Engine</option>
                <option value="event">Event</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="px-8 py-3 bg-[#14b7cd] text-white rounded-full hover:bg-[#14b7cd]/90 transition-colors duration-300 flex items-center"
              >
                Submit Application
                <svg
                  className="w-5 h-5 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Apply;
