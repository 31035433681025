import React, { FC, useEffect } from "react";
import { Link, useNavigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";

interface NavItem {
  path: string;
  label: string;
  icon: string;
}

interface NavGroup {
  title: string;
  items: NavItem[];
}

const AdminLayout: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/admin/login");
    }
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  const navGroups: NavGroup[] = [
    {
      title: "Dashboard",
      items: [{ path: "/admin", label: "Overview", icon: "chart-bar" }],
    },
    {
      title: "Content Management",
      items: [
        { path: "/admin/courses", label: "Courses", icon: "book-open" },
        { path: "/admin/categories", label: "Categories", icon: "folder" },
        { path: "/admin/course-tools", label: "Course Tools", icon: "tools" },
      ],
    },
    {
      title: "Live Sessions",
      items: [
        { path: "/admin/live-sessions", label: "Sessions", icon: "video" },
        { path: "/admin/calendar", label: "Calendar", icon: "calendar" },
      ],
    },
    {
      title: "Programs",
      items: [
        {
          label: "All Programs",
          path: "/admin/programs",
          icon: "book-open",
        },
        // {
        //   label: "Add Program",
        //   path: "/admin/programs/add",
        //   icon: "plus",
        // },
        {
          label: "Applications",
          path: "/admin/programs/applications",
          icon: "user-circle",
        },
      ],
    },
    {
      title: "User Management",
      items: [
        { path: "/admin/users", label: "Users", icon: "users" },
        {
          path: "/admin/subscribers",
          label: "Subscribers",
          icon: "user-circle",
        },
        {
          path: "/admin/subscriptions",
          label: "Subscription Plans",
          icon: "crown",
        },
      ],
    },
    {
      title: "System",
      items: [{ path: "/admin/settings", label: "Settings", icon: "cog" }],
    },
  ];

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    logout();
    navigate("/admin/login");
    setShowLogoutModal(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Top Navigation */}
      <header className="bg-white shadow-sm fixed w-full z-10">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex items-center space-x-4">
            <img src="/logo.png" alt="Logo" className="h-8" />
            <h1 className="text-xl font-semibold">Admin Dashboard</h1>
          </div>
          <div className="flex items-center">
            <div className="text-sm text-gray-600">Welcome, {user?.email}</div>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <aside className="fixed left-0 top-16 h-[calc(100vh-4rem)] w-64 bg-white shadow-sm border-r">
        <nav className="flex flex-col h-full p-4">
          {/* Menu Groups */}
          <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {navGroups.map((group, index) => (
              <div key={group.title} className={index !== 0 ? "mt-6" : ""}>
                <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
                  {group.title}
                </h3>
                {group.items.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`flex items-center px-4 py-2 rounded-lg mb-1 ${
                      location.pathname === item.path
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-50"
                    }`}
                  >
                    <i className={`fas fa-${item.icon} w-5`}></i>
                    <span className="ml-3">{item.label}</span>
                  </Link>
                ))}
              </div>
            ))}
          </div>

          {/* Logout Button */}
          <div className="border-t pt-4">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-left text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              <i className="fas fa-sign-out-alt w-5"></i>
              <span className="ml-3">Logout</span>
            </button>
          </div>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="ml-64 pt-16 min-h-screen">
        <div className="p-6">
          <Outlet />
        </div>
      </main>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Confirm Logout
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowLogoutModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={confirmLogout}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 text-sm font-medium"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminLayout;
